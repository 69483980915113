
.switch-medium {
    height: 22px;
    margin-top: 10px;
    outline: none;
}

.switch-medium small {
    width: 22px;
    height: 22px;
    left: 0;
    outline: none;
}

.switch small {
    top: -1px;
}

.switch-reduce-benefit-service {
    .switch-medium {
        margin-top: 0;
        height: 27px;
        margin-bottom: 0;
        small {
            width: 27px;
            height: 27px;
        }
    }
    
}
