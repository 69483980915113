@import "./variables";

.ngx-datatable {
    min-width: 100% !important;
    box-shadow: none !important;
    overflow: visible !important;

    .jumbotron {
        background-color: #fdfdfd;
        box-shadow: 8px 4px 3px 1px rgb(0 0 0 / 2%);
        .search-off {
            font-size: medium;
            margin-left: 2%;
        }

        .display-4 {
            font-size: medium;
        }
    }

    .datatable-scroll {
        width: 100% !important;
    }

    .datatable-row-detail {
        position: relative;
        height: auto !important;
        padding: 0 !important;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .datatable-body-row.datatable-row-even {
        background-color: #f0f7ff;
    }

    .display-4 {
        color: $title-color;
    }

    .datatable-body-cell {
        display: inline-grid !important;
        align-items: center !important;
    }

    .datatable-header-cell,
    .datatable-header-cell-label,
    .datatable-body-cell-label {
        font-family: Mulish, sans-serif;
        text-align: center !important;
        vertical-align: middle !important;
        color: #8c969f;
    }

    .datatable-body {
        min-height: 400px !important;
    }

    .datatable-header-cell {
        text-transform: uppercase;
        padding: 0 1.2rem !important;
        height: 38px;

        .datatable-header-cell-template-wrap {
            display: inline-flex;
            align-items: center;

            i {
                font-size: 1rem;
            }

            .form-control {
                width: 80%;
                display: inline;
                padding: 0.375rem 0.75rem;
            }

            .datatable-header-cell-label {
                margin-left: 5px;
                margin-right: 5px;

                cursor: context-menu;
            }
        }
    }

    .datatable-body-cell-overflow {
        overflow: visible !important;
        .datatable-body-cell-label {
            overflow: visible !important;
        }
    }

    .datatable-body-cell-buttons {
        overflow: visible !important;
        .datatable-body-cell-label  {
            overflow: visible !important;
        }

        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .datatable-body-cell-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .datatable-body-cell-checkbox {
        overflow: visible !important;
        .datatable-body-cell-label  {
            line-height: 0 !important;
            font-size: 14px !important;
            overflow: visible !important;
        }
    }

    .datatable-checkbox input[type=checkbox]:not(:checked):before {
        content: none;
    }
    .datatable-checkbox input[type=checkbox]:after  {
        content: none;
    }
    .datatable-checkbox input[type=checkbox]:checked:before {
        content: none;
    }
    .datatable-checkbox input[type=checkbox] {
        margin-top: 0.3rem;
    }
    .page-count {
        overflow: hidden;
    }

    .datatable-body {
        min-height: calc(100vh - (100vh * 0.55)) !important;
    }

    .datatable-row-clickble:hover {
        cursor: pointer;
    }
}
